<template>
  <v-layout column justify-center fill-height class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="instructions" />
    </v-flex>
    <v-flex shrink>
      <TextField
        class="mission-text-feild"
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="onSubmit"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "./ResizableText.vue"
import TextField from "./TextField.vue"
export default {
  name: "TextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  // mounted() {
  //   // save the initial length
  //   const waitingTextLength = this.waitingText.length
  //   this.interval = setInterval(() => {
  //     if (this.waitingText.length > waitingTextLength + 2)
  //       this.waitingText = this.waitingText.substr(0, waitingTextLength)
  //     else this.waitingText = this.waitingText + "."
  //   }, 600)
  // },
  // beforeDestroyed() {
  //   clearInterval(this.interval)
  // },
  data() {
    return {
      // interval: null,
      waitingText: "Waiting on Scribe..."
    }
  },
  computed: {
    ...mapGetters("auth", ["isAudit", "isSpectator", "isHost"]),
    ...mapGetters([
      "missionFail",
      "isScribe",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionAnswers",
      "getCurrentMission"
    ]),
    isTeamMission() {
      return this.missionPlayType === "team"
    },
    instructions() {
      return this.getCurrentMission.instructions
    },
    correct() {
      const [answer] = this.getCurrentMission.answer.split(",")
      return answer
    },
    behavior() {
      return this.getCurrentMission.behavior
    },
    showTextField() {
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      const { mode } = this
      return (
        (mode === "play" || mode === "huddle" || mode === "social") &&
        !this.isAudit &&
        !this.isSpectator
      )
    },
    status() {
      const {
        missionSuccess,
        missionFail,
        isHost,
        missionCompleted,
        isScribe,
        canPlay,
        isAudit,
        isSpectator
      } = this
      if (missionSuccess) {
        return "correct"
      } else if (missionFail) {
        return "wrong"
      } else if (!isHost && !missionCompleted && isScribe && canPlay) {
        return "active" // + lineThroughClass
      } else if (isAudit || isSpectator) {
        return "You are auditing"
      } else {
        return "active"
      }
    },
    lineThroughClass() {
      return this.missionFail ||
        (this.missionAnswersLength > 0 && !this.missionSuccess)
        ? " line-through"
        : ""
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    placeholder() {
      const {
        isHost,
        correct,
        isScribe,
        waitingText,
        isTeamMission,
        missionAnswers,
        missionAnswersLength,
        missionSuccess,
        missionCompleted,
        behavior,
        missionFail,
        canPlay,
        isAudit,
        isSpectator
      } = this
      if (isHost) {
        // always show the asnwer to the host
        return correct
      } else if (isAudit || isSpectator) {
        // if scrive and ready to go
        return "You are auditing.  Answer: " + correct
      } else if (!isScribe && !missionAnswersLength) {
        // no data entered for a non scribe
        return waitingText
      } else if (isTeamMission && missionAnswersLength && !missionCompleted) {
        // set the placeholder message for a non scribe
        // to the latest enetered by a scribe
        const answer = missionAnswers[missionAnswersLength - 1]
        return typeof answer === "string" ? answer : ""
      } else if (missionSuccess) {
        if (behavior == "Freeform") {
          return "Received!"
        } else {
          return "Correct!"
        }
      } else if (missionFail) {
        return correct
      } else if (isScribe && canPlay) {
        // if scrive and ready to go
        return "Enter here..."
      } else {
        // scribe but not ready to go
        return "Wait..."
      }
    }
  },
  methods: {
    async onSubmit(data) {
      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.answer = data
      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.text-input-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
}
</style>
